import React from "react"
import Layout from '../components/mylayout'
import Recent from '../components/recent-posts'
import IconPack from '../components/iconpack'
import SEO from '../components/seo'

const Home = ({location}) => {
  return(
    <Layout location={location}>
    <SEO 
        title="Roshan R" 
        description="Hey, i am Roshan. I’m a CSE Undergraduate Student, 
        Web Developer and a Quantum Computing Enthusiast from Kerala, India"
    />
    
    <div className="Hero">
        <div className="container">
            <div className="content">
                <h1 className="heading">Roshan Rajeev</h1>
                <p className="sub-heading">Code, Quantum, AI</p>
                <IconPack/>
            </div>
        </div>
    </div>

    <Recent/>
    </Layout>
  )
}

export default Home;
