import React from 'react'
import {Link} from 'gatsby'
import TitleComponent from './title-component'
import {useStaticQuery, graphql} from 'gatsby'

const Recent = () =>{
    const blogdata = useStaticQuery(graphql`
        query {
            allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 4) {
              edges {
                node {
                  frontmatter {
                    title
                    description
                  }
                  fields {
                    slug
                  }
                }
              }
            }
          }          
    `)

    const bdata = blogdata.allMarkdownRemark.edges.map((d,i)=>{
        return(
            <li className="Recents-post-item" key={i}>
                <Link to={`/blog${d.node.fields.slug}`}>
                    <p className="sub-heading" >{d.node.frontmatter.title}</p>
                    <p className="paragraph">{d.node.frontmatter.description}</p>
                </Link>
            </li>
        )
    })
    
    return(
        <div className="Recents">
            <div className="container">
                <div className="row">
                    <div className="Recents-blog col-12">
                        <TitleComponent text="Blog Posts"/>
                        <div className="d-md-none">
                            <ul className="Recents-list">
                                {bdata}
                            </ul>
                        </div>
                        <div className="row">
                            {bdata.map(d => {
                                return(
                                    <div className="col-6 d-none d-md-block">
                                        <ul className="Recents-list">
                                            {d}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                        <Link to="/blog" className="more-button">See All Posts</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recent;