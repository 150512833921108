import React from 'react'
import Line from '../images/line.svg'
const TitleComponent = (props)=> {
    let classes="";
    if(props.color){
        classes=props.color;
    }
    return(
        <p className="TitleComponent">
            <img className={classes} src={Line} alt="title decoration"/>
            &nbsp;{props.text}
        </p>
    )
}

export default TitleComponent